import Moment from "moment";
import React, { useContext } from 'react';
import {
    Calendar as BigCalendar,
    momentLocalizer
} from 'react-big-calendar';
import { useHistory } from "react-router-dom";
import { Segment } from 'semantic-ui-react';
import { ConfigContext } from '../../contexts/ConfigContext';
import { LoginContext } from '../../contexts/LoginContext';
import { UIContext } from '../../contexts/UIContext';
import Year from "./YearView";

const Calendar = ({
    tableColumnGroups,
    stats,
    setSortField,
    setSortDirection,
    sortDirection,
    records,
    resources,
    selected,
    handleSelection,
    model,
    view,
    relationship,
    parentModel,
    parentRecord,
    setCalendarViewFromDate,
    setCalendarViewToDate,
    setCalendarView,
    toDate,
    fromDate,
    saveCalendarDateToLocalStorage,
    defaultCalendarDate,
    saveCalendarViewToLocalStorage,
    defaultCalendarView
}) => {

    const { addToEditModalQueue, refreshDataKey, refreshData, runAction, runDeletion, runBulkDeletion } = useContext(UIContext)


    const { 0: config } = useContext(ConfigContext)
    const { 0: login } = useContext(LoginContext)

    let history = useHistory()

    Moment.locale("en-gb", {
        week: {
            dow:model.calendarFirstDayOfWeek || 0
        }
    })
    
    const localizer = momentLocalizer(Moment);
    localizer.formats.yearHeaderFormat = 'YYYY';

    let calendarResources = []
    if(resources){
        calendarResources = resources.map(r => {
            return {
                resourceId: r.id,
                resourceTitle: config.models[model.resourceModel].referToByString(r)
            }
        })
    }

    console.log(fromDate, toDate, defaultCalendarDate, view)

    return <div className='calendarHolder'>
        <Segment style={{ borderTop: `2px solid ${config.primaryColour}` }}>
            <BigCalendar
                localizer={localizer}
                defaultDate={defaultCalendarDate?new Date(defaultCalendarDate):new Date()}//will default to now if null
                // date={fromDate}
                // date={new Date()}
                showMultiDayTimes={true}
                selectable={true}
                resources={calendarResources}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                view={view}
                popup={true}
                views={{'day': true, 'month':true, 'year':Year}}
                showAllEvents={true}
                min={new Date(new Date().setHours(8, 0, 0, 0))}
                onSelectSlot={(e)=>{
                    
                    if(view == 'month'){
                    
                        saveCalendarDateToLocalStorage(e.start)
                        setCalendarViewFromDate(Moment(e.start))
                        setCalendarViewToDate(Moment(e.start).endOf('day'))
                        
                        setCalendarView('day')
                        saveCalendarViewToLocalStorage('day')
                    }

                    if(view == 'day'){
                        addToEditModalQueue({
                            model,
                            parentModel,
                            parentRecord,
                            setData:{
                                [model.calendarStartField]:e.start,
                                [model.calendarEndField]:e.end
                            }
                        })
                    }
                    
                }}
                // events={events}
                events={records.map(r => {

                    if(view == 'month'){
                        return {
                            id: r.id,
                            record: r,
                            resourceId: r[model.resouceIdField],
                            title: `${Moment(r[model.calendarStartField]).format('HH:mm')} - ${Moment(r[model.calendarEndField]).format('HH:mm')} ${model.referToByString(r)}`,
                            start: Moment(r[model.calendarStartField]).toDate(),
                            end: Moment(r[model.calendarEndField]).toDate(),
                            allDay: false
                        }
                    }

                    if(view == 'day'){
                        return {
                            id: r.id,
                            record: r,
                            resourceId: r[model.resouceIdField],
                            title: model.referToByString(r),
                            start: Moment(r[model.calendarStartField]).toDate(),
                            end: Moment(r[model.calendarEndField]).toDate(),
                            allDay: false
                        }
                    }
                })}
                //tooltipAccessor= {`title`}
                onSelectEvent={event => history.push(model.linkTo(event))}
                eventPropGetter={(event, start, end, isSelected)=>{
                    let colour = '#228B22'

                    //if(event.record.status == 'Provisional') colour = '#8B0000'
                    if(model.calendarColour){
                        colour = model.calendarColour(event.record)
                    }

                    return {
                        style: {
                            backgroundColor:colour
                        }
                    }
                }}
                //detect date range change
                onView={(view)=>{
                    setCalendarView(view)
                    saveCalendarViewToLocalStorage(view)
                }}
                onRangeChange={(range)=>{
                    
                    //if an array
                    if(Array.isArray(range)){
                        saveCalendarDateToLocalStorage(range[0])
                        setCalendarViewFromDate(Moment(range[0]))
                        setCalendarViewToDate(Moment(range[range.length-1]).endOf('day'))
                    }
                    //else object (month view)
                    else {

                        //if the range start the 1st of the month
                        let startOfMonth = Moment(range.start).date()==1?range.start:Moment(range.start).add(1, 'week').startOf('month').toString()
                        saveCalendarDateToLocalStorage(startOfMonth)

                        setCalendarViewFromDate(Moment(startOfMonth))
                        setCalendarViewToDate(Moment(range.end))
                    }

                    
                }}
                messages={{
                    year: 'Year',
                }}
                //dayLayoutAlgorithm='no-overlap'
                //selectable
                //popup={true}
                // [{
                //     id: 23,
                //     title: 'Go to the gym',
                //     start: new Date(2020, 10, 14, 18, 30, 0),
                //     end: new Date(2020, 10, 14, 20, 0, 0),
                // }]}
                // startAccessor="start"
                // endAccessor="end"
                // allDayAccessor="allDay"

                // style={{ height: 800 }}
            />
        </Segment>
    </div>
}

export default Calendar